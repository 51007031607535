import 'slick-carousel';
import $ from 'jquery';
function initSlickSliders(varFromPhp) {
    const sliders = {
        newQuote: $('.js-new-quote-slider'),
        trustLogo: $('.js-partners-items'),
        heroGroup1: $('.js-hero-ver-2-group-1'),
        heroGroup2: $('.js-hero-ver-2-group-2'),
        heroGroup3: $('.js-hero-ver-2-group-3'),
    };
    const speeds = {
        group1: varFromPhp.hero_version_2_group_1_speed || 5000,
        group2: varFromPhp.hero_version_2_group_2_speed || 5000,
        group3: varFromPhp.hero_version_2_group_3_speed || 5000,
    };
    const activeSliders = new Set();
    function isInFocus(element) {
        if (!element || document.hidden)
            return false;
        const rect = element.getBoundingClientRect();
        return (!document.hidden &&
            document.hasFocus() &&
            rect.top < window.innerHeight &&
            rect.bottom > 0 &&
            rect.left < window.innerWidth &&
            rect.right > 0);
    }
    function updateSliderState(slider) {
        if (!slider.length)
            return;
        if (isInFocus(slider[0])) {
            slider.slick('slickPlay');
        }
        else {
            slider.slick('slickPause');
        }
    }
    function updateAllSliders() {
        activeSliders.forEach(updateSliderState);
    }
    function initSlider(slider, options) {
        if (!slider.length)
            return;
        slider.slick(options);
        activeSliders.add(slider);
        const observer = new IntersectionObserver(() => {
            updateSliderState(slider);
        }, { threshold: 0.2 });
        observer.observe(slider[0]);
        slider.data('observer', observer);
    }
    document.addEventListener('visibilitychange', updateAllSliders);
    window.addEventListener('blur', updateAllSliders);
    window.addEventListener('focus', updateAllSliders);
    window.addEventListener('scroll', updateAllSliders, { passive: true });
    if (sliders.trustLogo.length) {
        initSlider(sliders.trustLogo, {
            centerMode: true,
            dots: false,
            infinite: true,
            swipe: false,
            speed: 5000,
            centerPadding: '50px',
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            cssEase: 'linear',
            variableWidth: true,
            arrows: false,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                        swipe: true,
                        arrows: false,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 6,
                        swipe: true,
                        arrows: false,
                        infinite: true,
                        speed: 3000,
                    },
                },
            ],
        });
    }
    if (sliders.heroGroup1.length && sliders.heroGroup2.length && sliders.heroGroup3.length) {
        const commonOptions = {
            vertical: true,
            dots: false,
            swipe: false,
            speed: 5000,
            slidesToShow: 5,
            autoplay: true,
            autoplaySpeed: 0,
            cssEase: 'linear',
            arrows: false,
            pauseOnHover: true,
        };
        initSlider(sliders.heroGroup1, Object.assign(Object.assign({}, commonOptions), { speed: speeds.group1 }));
        initSlider(sliders.heroGroup2, Object.assign(Object.assign({}, commonOptions), { speed: speeds.group2 }));
        initSlider(sliders.heroGroup3, Object.assign(Object.assign({}, commonOptions), { speed: speeds.group3 }));
    }
    if (sliders.newQuote.length) {
        initSlider(sliders.newQuote, {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 10000,
            arrows: false,
        });
    }
    return {
        cleanup: () => {
            document.removeEventListener('visibilitychange', updateAllSliders);
            window.removeEventListener('blur', updateAllSliders);
            window.removeEventListener('focus', updateAllSliders);
            window.removeEventListener('scroll', updateAllSliders);
            activeSliders.forEach((slider) => {
                const observer = slider.data('observer');
                if (observer)
                    observer.disconnect();
                if (slider.length)
                    slider.slick('unslick');
            });
            activeSliders.clear();
        },
    };
}
const SlickSliders = { initSlickSliders };
export default SlickSliders;
