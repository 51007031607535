import 'slick-carousel';
import $ from 'jquery';
const initIndustriesItemsSlider = () => {
    $('.js-industries-items').slick({
        centerMode: true,
        autoWidth: true,
        dots: true,
        infinite: false,
        swipe: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 0,
        cssEase: 'linear',
        variableWidth: true,
        arrows: false,
        pauseOnHover: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoWidth: true,
                    dots: true,
                    swipe: true,
                    arrows: false,
                    infinite: false,
                    adaptiveHeight: true,
                    autoplay: false,
                    variableWidth: false,
                    speed: 1000,
                },
            },
        ],
    });
};
export default initIndustriesItemsSlider;
